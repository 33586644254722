/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import 'perfect-scrollbar/css/perfect-scrollbar.css';

@import '@mobiscroll/angular/dist/css/mobiscroll.scss';
@import '@mobiscroll/angular5/dist/css/mobiscroll.scss';

@import 'photoswipe/dist/photoswipe.css';
@import 'photoswipe/dist/default-skin/default-skin.css';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@import 'theme/colors.scss';

.camera-preview-content {
  --background: transparent;
}

/* indicators for slidable items */
ion-item-sliding {
  position: relative;
}

ion-item-sliding {
  ion-item:before {
    content: '';
    background-color: #cccccc;
    display: block;
    position: absolute;
    right: 4px;
    width: 4px;
    top: 8px;
    bottom: 8px;
    border-radius: 2px;
    z-index: 10;
  }
}

.modal-fullscreen {
  --border-radius: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.modal-large {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 80%;
    height: 50%;
  }
}

.modal-tall {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 80%;
    height: 90%;
  }
}

div.panel-message-wrapper {
  width: 100%;
  height: 100%;
  display: grid;

  place-items: center;

  div.panel-message {
    font-size: 28px;
    color: #999999;
    text-align: center;
    width: 100%;
  }
}

div.card-message-wrapper {
  width: 100%;
  height: 300px;
  display: grid;
  place-items: center;

  div.card-message {
    font-size: 28px;
    color: #999999;
    text-align: center;
    width: 100%;
  }
}

div.shadow {
  position: absolute;
  width: 8px;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0)
  );
  z-index: 10000;
}

div.map-holder {
  width: 100%;
  height: 100%;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px rgb(78, 143, 247, 1);
  }

  100% {
    box-shadow: 0px 0px 0px 25px rgba(255, 255, 255, 0);
  }
}

div.location-marker {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: rgb(78, 143, 247);
  border: 2px solid #ffffff;
  position: absolute;
  animation: pulse 2.4s infinite ease-out;
  transform: translate(-50%, -50%);
}

.line-card {
  margin: 10px;
  border-top: 3px solid var(--ion-color-medium);
  box-shadow: 0px 1px 2px #aaaaaa;
  background-color: #ffffff;

  &.selectable {
  }

  .line-card-header {
    position: relative;
    z-index: 0;

    h4 {
      margin: 8px 15px;
    }

    ion-title {
      text-align: left !important;
    }

    ion-note {
      padding-right: 15px;
    }
  }

  .line-card-content {
    padding: 0px 15px 15px 15px;

    ion-toolbar {
      position: relative;
      z-index: 0;
    }

    ion-item {
      ion-note {
        margin-top: 16px;
        font-size: 14px;
      }
    }

    &.no-h-padding {
      padding: 0 0 15 0 !important;
    }
  }

  &.warning {
    border-top: 3px solid var(--ion-color-warning);

    .line-card-header {
      h4,
      ion-title {
        --color: var(--ion-color-warning);
      }
    }
  }

  &.warningt {
    border-top: 3px solid var(--ion-color-warning);

    .line-card-header {
      h4,
      ion-title {
        --color: var(--ion-color-warningt-contrast);
      }
    }
  }

  &.danger {
    border-top: 3px solid var(--ion-color-danger);

    .line-card-header {
      h4,
      ion-title {
        --color: var(--ion-color-danger);
      }
    }
  }

  &.success {
    border-top: 3px solid var(--ion-color-success);

    .line-card-header {
      h4,
      ion-title {
        --color: var(--ion-color-success-shade);
      }
    }
  }

  &.primary {
    border-top: 3px solid var(--ion-color-primary);

    .line-card-header {
      h4,
      ion-title {
        --color: var(--ion-color-primary-shade);
      }
    }
  }

  &.medium {
    border-top: 3px solid var(--ion-color-medium);

    .line-card-header {
      h4,
      ion-title {
        --color: var(--ion-color-medium-shade);
      }
    }
  }

  &.light {
    border-top: 3px solid var(--ion-color-light-shade);

    .line-card-header {
      h4,
      ion-title {
        --color: var(--ion-color-light-shade);
      }
    }
  }

  ion-list {
    margin-bottom: 0px;
  }
}

app-planner.desktop {
  --row-height: 22px;
  --font-height: 13px;
  --lg-font-height: 15px;
  --bar-top: 4px;
  --bar-bottom: 4px;
  --thin-bar-top: 7px;
  --thin-bar-bottom: 7px;
  --label-width: 460px;
  --edit-button-margin-right: 55px;
  --locate-status-right: 25px;
}

app-planner {
  --day-width: 60px;
  --num-days: 41;
  --row-width: calc(var(--day-width) * var(--num-days));
  --label-width: 350px;
  --header-height: 60px;
  --footer-height: 58px;
  --row-height: 40px;
  --font-height: 14px;
  --lg-font-height: 16px;
  --bar-top: 10px;
  --bar-bottom: 10px;
  --edit-button-margin-right: 25px;
  --locate-status-right: 0px;
  --bar-h-padding: 10px;
  --bar-h-bound: 12px;

  @for $i from 2 through 50 {
    .days-#{$i} {
      width: calc(var(--day-width) * #{$i});
    }
  }

  .day {
    width: var(--day-width);
    border-right: 1px solid #bdbdbd;
    box-sizing: border-box;
    color: #555555;
  }

  .day.weekend {
    background-color: #f1f1f1;
    color: #999999;
  }

  .day.ldom {
    border-right: 1px solid #444444 !important;
  }

  .day.today {
    border-right: 1px solid #27ae60;
    color: #207542;
    border-bottom: 3px solid #207542;
    background-color: #e3ffef91;
  }

  .data-row {
    width: 100%;
    height: var(--row-height);
    position: relative;
    line-height: var(--row-height);
  }

  .data-row.lg {
    height: 60px;
    line-height: 60px;
  }

  .jp-summary-bar {
    border: 2px solid #296596 !important;
    background-color: #296596;

    &.missing-crew {
      background-image: repeating-linear-gradient(
        45deg,
        #296596,
        #296596 5px,
        #759ab8 5px,
        #759ab8 10px
      );
    }

    .handle {
      border: none !important;
    }

    &.tentative {
      background-color: rgba(41, 101, 150, 0.5);
    }
  }

  .bar-crew-req {
    .bar {
      background-color: rgba(255, 195, 0, 0.5) !important;
      border-color: #ffc300 !important;
    }

    .segment {
      background-color: #ffc300;

      &.no-crew {
        background-image: repeating-linear-gradient(
          45deg,
          #ffc300,
          #ffc300 5px,
          #fff4b7 5px,
          #fff4b7 10px
        );
      }

      &.conflict {
        background-image: repeating-linear-gradient(
          45deg,
          #ff0000,
          #ff0000 5px,
          #ffb7b7 5px,
          #ffb7b7 10px
        );
      }
    }
  }

  .bar-equip-active {
    .bar {
      top: var(--thin-bar-top) !important;
      bottom: var(--thin-bar-bottom) !important;
      background-color: transparent !important;
      border: none !important;

      .segment {
        background-color: rgba(0, 134, 7, 0.5) !important;

        &.active-today {
          background-color: transparent !important;
          // background-image: linear-gradient(
          //   to right,
          //   rgba(0, 134, 7, 0.5),
          //   rgba(0, 134, 7, 0)
          // ) !important;
          background-image: repeating-linear-gradient(
            to right,
            rgba(0, 134, 7, 0.5),
            rgba(0, 134, 7, 0.5) 3px,
            rgba(0, 134, 7, 0) 3px,
            rgba(0, 134, 7, 0) 6px
          );
        }
      }
    }
  }

  .bar-equip-req {
    .bar {
      background-color: rgba(0, 134, 7, 0.5) !important;
      border-color: #008607 !important;
    }

    .segment {
      background-color: #008607;

      &.missing-equip {
        background-image: repeating-linear-gradient(
          45deg,
          #008607,
          #008607 5px,
          #cdffd0 5px,
          #cdffd0 10px
        );
      }
    }
  }

  .crew-bar {
    border: 2px solid #ffc300 !important;
    background-color: #ffc300;

    &.no-crew {
      background-image: repeating-linear-gradient(
        45deg,
        #ffc300,
        #ffc300 5px,
        #fff4b7 5px,
        #fff4b7 10px
      );
    }

    &.conflict {
      background-image: repeating-linear-gradient(
        45deg,
        #ff0000,
        #ff0000 5px,
        #ffb7b7 5px,
        #ffb7b7 10px
      );
    }

    &.creating {
      background-color: rgba(255, 195, 0, 0.7);
    }

    .handle {
      border: none !important;
    }
  }

  .equip-bar {
    border: 2px solid #008607 !important;
    background-color: #008607;

    &.missing-equip {
      background-image: repeating-linear-gradient(
        45deg,
        #008607,
        #008607 5px,
        #cdffd0 5px,
        #cdffd0 10px
      );
    }

    &.creating {
      background-color: rgba(0, 134, 7, 0.7);
    }

    .handle {
      border: none !important;
    }
  }
}

.ps__rail-x,
.ps__rail-y {
  z-index: 15000;
}

ion-modal.bar-edit-modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: none;

  &::part(backdrop) {
    display: none;
  }

  &::part(content) {
    position: absolute;
    border-radius: 0;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: none;
  }

  h6 {
    margin-top: 5px;
    margin-bottom: 3px;
  }

  h5 {
    margin-top: 5px;
    margin-bottom: 3px;
  }

  p {
    margin: 0 0 10px 0px;
    font-size: 12px;

    &.crew {
      margin-left: 10px;
    }

    &.crew-req-name {
      text-decoration: underline;
    }
  }
}

ion-modal.datetime-date-modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: none;

  &::part(backdrop) {
    display: none;
  }

  &::part(content) {
    position: absolute;
    border-radius: 0;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: none;
  }
}

.labeled-item {
  display: flex;
  margin-top: 10px;

  &.vertical {
    flex-direction: column;

    .value {
      text-align: left;
      margin-top: 3px;
      margin-left: 10px;
    }
  }

  .label {
    color: var(--ion-color-medium);
    font-size: 14px;
    text-transform: uppercase;
    width: 160px;
  }

  .value {
    text-align: right;
  }
}

.picker-hours {
  width: 47.5%;
  text-align: right;
}

.picker-minutes {
  width: 47.5%;
  text-align: left;
}

.datetime-date-modal {
  .modal-wrapper {
    background: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: none;
    position: absolute;
  }
}

app-vacation-calendar,
app-on-call-calendar {
  .mbsc-cal-cell {
    &.mbsc-cal-day-diff {
      background-color: #eeeeee;
    }
  }
}

.mbsc-cal-cell {
  &.vacation {
    background-color: rgba(56, 128, 255, 0.15);
  }
}

.subnote {
  font-size: 12px;
  color: var(--ion-color-medium);
}

.vacation {
  background: var(--ion-color-danger);
}

.cupertino-pane-wrapper {
  &.bottom-drawer {
    .close-button {
      z-index: 100;
    }
  }
}

.mbsc-fr-lock-ctx.ion-page {
  position: absolute;
}

.job-group-header {
  background-color: #cccccc;
}
